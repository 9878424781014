export const ROUTE_PATH = {
  HOME: '/',
  PREVIEW_MODEL: '/preview',
  INTRODUCE: '/introduce',
  MODEL_BRAIN: '/model-brain',
  MODEL_STOMACH: '/model-stomach',
  MODEL_INTESTINE: '/model-intestine',
  FINAL_CHAPTER: '/final-chapter',
  END_OF_SROTY: '/end-of-story'
};
